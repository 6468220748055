import React, { useEffect, useState, useContext } from "react";
import Modal from "react-modal";
import { Grid } from "@mui/material";
import RichTextEditor from "../../../pages/config/rich-texteditor";
import { TextField, Select, MenuItem, Tooltip } from "@mui/material";
import { TextareaAutosize } from "@material-ui/core";
import "../project.scss";
import dateFormat from "dateformat";

import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import FormControl from "@material-ui/core/FormControl";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { ReactComponent as Multiplefiles } from "../../../assets/multiple-files-icon.svg";

import { ReactComponent as VideoIcon } from "../../../assets/feather-video-icon.svg";
import AttachFile from "@mui/icons-material/AttachFile";
import { SelectDropdown } from "./select-dropdown";
import * as yup from "yup";
import moment from "moment";
import { Field, useFormik, FormikProvider } from "formik";
import {
  CalendarTodayOutlined,
  AddCircle,
  DeleteOutline,
} from "@mui/icons-material";
import DatePickerView from "react-datepicker";
import { ReactComponent as Attachments } from "../../../assets/attachment_icon.svg";

import { ReactComponent as AttachmentIconBlue } from "../../../assets/attachment_blue_24dp.svg";
import {
  FORM_FIELDS,
  FORM_FIELDS_ARRAY,
  MESSAGES,
  DEFECT_ATTACTMENT_FILE_ALLOWED,
} from "../../config/defectConstant";
import { checkValidationForAlphanumericSpecialCharacters } from "../../config/defectvalidation";
import { CustomDefectDropDown } from "../../config/customDefectDropDown";
import Addplatform from "../modals/add-pltform";
import {
  createDefectReq,
  UpdateDefectReq,
  deleteMultipleFilesReq,
  getModuleAndChildModuleReq,
  getModuleAndChildModuleByEmailReq,
} from "../../../api/api-services";
import "../project.scss";
import { CommonLoader } from "../../common/common-loader";
import { ProgressContainerContext } from "../../common/inputs/ProgressContainer";
import DefectTreeWithRadioButton from "./defect-tree-with-radioButton";
import ChangeStatusWarning from "./changeStatusWarning";
import DefectReasonModal from "../../modal/defect-reason-modal";
import { ReactComponent as ApprovedShortBtn } from "../../../assets/approvedShortBtn.svg";
import { ReactComponent as RejectedShortBtn } from "../../../assets/rejectedShortBtn.svg";


function CreateDefect({ selectedModule, modalType, ...props }) {
  console.log("CreateDefect",props)
  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );
  const [openModal, setOpenModal] = useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCreateAnotherDefect, setIsCreateAnotherDefect] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [validateSchema, setValidateSchema] = useState({});
  const [defectTemplateFields, setDefectTemplateFields] = useState([]);
  const [moduleTreeData, setModuleTreeData] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [tempUploadFiles, setTempUploadFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [newFilesToBeUploaded, setNewFilesToBeUploaded] = useState([]);
  const [platformdetails, setplatformdetails] = useState(props?.platform);
  const [changePlatfrom, setChangeplatfrom] = useState(false);
  const [selectedplatform, setSelectedplatform] = useState([]);
  const [webplatform, setWebplatform] = useState(props?.webplatform);
  const [mobileplatform, setMobileplatform] = useState(props?.Mobileplatform);
  const TemplateData = localStorage.hasOwnProperty("Default-defect-template")
    ? JSON.parse(localStorage.getItem("Default-defect-template"))
    : "";
  let myRole = JSON.parse(localStorage.getItem("user-privilege")).role;
  let [enablebtn, setEnableBtn] = useState(true);
  let [editfield, setEditField] = useState(false);
  let [editwebplatform, setEditWebplatform] = useState(false);
  let [editMobileplatform, setEditMobileplatform] = useState(false);
  let defectdata = props?.selectedDefectData ? props?.selectedDefectData : null;
  const [moduleTree, setModuleTree] = useState([]);
  const [moduleLevelScriptCount, setModuleLevelScriptCount] = useState(0);
  const [defaultSelectedModule, setDefaultSelectedModule] = useState([]);
  const [moduleValue,setModuleValue]=useState("");
  let projectID = JSON.parse(localStorage.getItem("selected-project")).project
    ?.fireFlinkProjectId;
  let licenceID = JSON.parse(
    localStorage.getItem("selected-project")
  ).licenceID;
  let EmailID = JSON.parse(localStorage.getItem("fire-crowd-user")).userName;
  const [statusWarnOpen, setStatusWarnOpen] = useState(false);
  const [dataForApprove, setDataForApprove] = useState([]);
  const [bugHistoryId, setBugHistoryId] = useState("");
  const [defectReasonModalOpen, setDefectReasonModalOpen] = useState(false);
  const {
    TEXTFIELD,
    TEXTBOX,
    DROPDOWN,
    CHECKBOX,
    RADIOBUTTON,
    LINK,
    ATTACHMENT,
    DATE,
    TEXTEDITOR,
  } = FORM_FIELDS;
  const {
    CREATE_SUCCESS,
    UPDATE_SUCCESS,
    ALPHA_ERR_MSG,
    CREATE_DEFECT_ERR,
    REQUIRED_ERR_MSG,
    NO_SPACE_AT_START_AND_END_MSG,
    YES,
    VALID_URL,
    MAX_NO_OF_FILES_ALLOWED,
    DUPLICATE_FILE,
    MAX_FILE_SIZE_ALLOWED,
    CREATE_DEFECT,
    EDIT_DEFECT,
    CREATE_ANOTHER_DEFECT,
    CREATE,
    UPDATE,
    ADD,
    EDIT,
    NO,
  } = MESSAGES;

  let tempValidateSchema = "";
  let tempInitialValues = {};

  useEffect(() => {
    createValidationSchema();
  }, []);
  useEffect(() => {
    if (myRole !== "ADMIN" && myRole !== "PROJECT_OWNER") {
      getAllTree();
    }
  }, []);
  async function getAllTree() {
    if (myRole === "ACCOUNT_MANAGER") {
      try {
        const response = await getModuleAndChildModuleReq(
          true,
          false,
          projectID,
          licenceID,
          EmailID
          // proj?.project?.fireFlinkProjectId,
          // proj?.licenceID
        );
        if (response?.data?.responseObject?.moduleTree?.length) {
          setModuleTree(response.data.responseObject.moduleTree);
          setModuleLevelScriptCount(
            response?.data?.responseObject?.moduleLevelScriptCount
          );
        } else {
          setModuleTree([]);
        }
      } catch (err) {
        console.error("GET_MODULE_TREE :", err);
      }
    } else {
      try {
        const response = await getModuleAndChildModuleByEmailReq(
          true,
          false,
          projectID,
          licenceID,
          EmailID
        );
        if (response?.data?.responseObject?.moduleTree?.length) {
          setModuleTree(response.data.responseObject.moduleTree);
          setModuleLevelScriptCount(
            response?.data?.responseObject?.moduleLevelScriptCount
          );
        } else {
          setModuleTree([]);
        }
      } catch (err) {
        console.error("GET_MODULE_TREE :", err);
      }
    }
  }
  function getplatformdeatils(platform) {
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    console.log("getplatformdeatils", platform);
    if (
      JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.web !== null || (JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.others?.typeOfProject ==="Standalone_Application")
    ) {
      if (props?.webplatform.id !== platform?.id) {
        resetProgress();
        setEditWebplatform(true);
      } else {
        resetProgress();
        setEditWebplatform(false);
      }
      resetProgress();
      setWebplatform(platform);
    }
    if (
      JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.mobile !== null || (JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.others?.typeOfProject!=="Standalone_Application")
    ) {
      if (props?.Mobileplatform.id !== platform?.id) {
        setEditMobileplatform(true);
      } else {
        setEditMobileplatform(false);
      }
      setMobileplatform(platform);
    }
    if (
      JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.webAndMobile !== null
    ) {
      setChangeplatfrom(true);
      if (platform?.platformType === "web") {
        if (props?.webplatform.id !== platform?.id) {
          resetProgress();
          setEditWebplatform(true);
        } else {
          resetProgress();
          setEditWebplatform(false);
        }
        resetProgress();
        setWebplatform(platform);
      } else {
        if (props?.Mobileplatform.id !== platform?.id) {
          resetProgress();
          setEditMobileplatform(true);
        } else {
          resetProgress();
          setEditMobileplatform(false);
        }
        resetProgress();
        setMobileplatform(platform);
      }
    }
  }
  function platformselect(value, type) {
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    if (type === "web") {
      if (props?.webplatform.id !== value?.id) {
        resetProgress();
        setEditWebplatform(true);
      } else {
        resetProgress();
        setEditWebplatform(false);
      }
      resetProgress();
      setWebplatform(value);
      console.log("platformselect web", value);
    } else {
      if (props?.Mobileplatform.id !== value?.id) {
        resetProgress();
        setEditMobileplatform(true);
      } else {
        resetProgress();
        setEditMobileplatform(false);
      }
      resetProgress();
      setMobileplatform(value);

      console.log("platformselect mobile", value);
    }
  }
  const ApprovedOrRejectDefect=(values,state)=>{
    console.log("approveddata",values);
    console.log("props?.selectedDefectData?.defectDetails",props?.selectedDefectData?.defectDetails["Created On"])
    setBugHistoryId(values?.ID)
    const data = {
      defectTemplateId: props?.selectedDefectData?.defectTemplateId,
      defectLifeCycleTemplateId:props?.selectedDefectData?.defectLifeCycleTemplateId,
      state_transitions:props?.selectedDefectData?.state_transitions,
      state:props?.selectedDefectData?.state,
      projectId:props?.selectedDefectData?.projectId,

    };
    const formValues = { ...values };
    formValues["Module"]=moduleValue
    formValues["Assign to"]=props?.selectedDefectData?.defectDetails["Assign to"];
    formValues["Created On"] =props?.selectedDefectData?.defectDetails["Created On"];
    formValues["Modified On"] =props?.selectedDefectData?.defectDetails["Modified On"];
    formValues["history"]=props?.selectedDefectData?.defectDetails["history"];
    formValues["source"] = "FireCrowd";
    formValues["platformDetails"]=props?.selectedDefectData?.defectDetails["platformDetails"];
    formValues["viewers"]=props?.selectedDefectData?.defectDetails["viewers"];
    formValues["allowTestersToEdit"]=props?.selectedDefectData?.defectDetails["allowTestersToEdit"];
    formValues["Reason"]=props?.selectedDefectData?.defectDetails["Reason"];
    data.defectDetails = formValues;
    console.log("upadteddeedsffd,",data)
     setDataForApprove(data)
     if(state==="approved"){
       setStatusWarnOpen(true)
     }else{
      setDefectReasonModalOpen(true)

    }
     

  }
  const handleOnSubmit = async (values) => {
    console.log("handleOnSubmit",values)
    console.log("web platform", webplatform);
    console.log("mobile platform", mobileplatform);
    console.log(
      "props?.selectedDefectData",
      props?.selectedDefectData?.defectDetails?.Module
    );
    console.log("values", moduleValue);
    setIsLoading(true);
    setEnableBtn(false);

    const { defectDetails } = props?.selectedDefectData;
    let projectId = JSON.parse(localStorage["selected-project"]).project
      .fireFlinkProjectId;
    let licenceID = JSON.parse(localStorage["selected-project"]).licenceID;
    let email = JSON.parse(localStorage.getItem("selected-user")).email;

    console.log("handle submit", values);

    let lead_role;
    JSON.parse(localStorage["selected-project"])?.project?.users?.userList.map(
      (user) => {
        if (
          user.email === JSON.parse(localStorage.getItem("selected-user")).lead
        ) {
          lead_role = user.role;
        }
      }
    );

    const data = {
      defectTemplateId: JSON.parse(
        localStorage.getItem("Default-defect-template")
      )._id,
      defectLifeCycleTemplateId: JSON.parse(
        localStorage.getItem("selected-project")
      ).project?.defaultDefectLifecycleTemplateId,
      // defectLifeCycleTemplateId:"DEFLIFTEMP1001",
      state_transitions: [
        {
          currentState: "INREVIEW",
          nextStates: ["APPROVED", "REJECTED", "CLOSED"],
        },
        {
          currentState: "APPROVED",
          nextStates: ["REJECTED", "CLOSED", "INREVIEW"],
        },
        {
          currentState: "REJECTED",
          nextStates: ["INREVIEW"],
        },
        {
          currentState: "CLOSED",
          nextStates: [],
        },
      ],
    };
    const formValues = { ...values };

    if (modalType === ADD) {
      formValues["Created By"] = {};
      formValues["Modified By"] = {};
      formValues["Assign to"] = {
        userEmail: JSON.parse(localStorage.getItem("selected-user"))?.lead,
        state: "null",
      };
      formValues["history"] = [
        {
          userId: JSON.parse(localStorage.getItem("selected-user")).id,
          userEmail: JSON.parse(localStorage.getItem("selected-user"))?.email,
          state: "INREVIEW",
          userRole: JSON.parse(localStorage.getItem("selected-user")).role,
          lead: JSON.parse(localStorage.getItem("selected-user")).lead,
          date: new Date(),
        },
      ];

      formValues["viewers"] = [
        {
          userEmail: JSON.parse(localStorage.getItem("selected-user"))?.email,
          role: JSON.parse(localStorage.getItem("selected-user")).role,
        },
        {
          userEmail: JSON.parse(localStorage.getItem("selected-user"))?.lead,
          role: lead_role,
        },
      ];
      formValues["State"] = "INREVIEW";
    } else {
      formValues["history"] = defectDetails.history;
      formValues["viewers"] = defectDetails.viewers;
      formValues["State"] = props.selectedDefectData.state;
      formValues["Created On"] =
        props.selectedDefectData.defectDetails["Created On"];
      formValues["Assign to"] = defectDetails["Assign to"];
      formValues["Module"]=moduleValue
      if (myRole === "TESTER") {
        formValues["Assign to"] = {
          userEmail: JSON.parse(localStorage.getItem("selected-user"))?.lead,
          state: "null",
        };
        let tempHistory = {
          userId: JSON.parse(localStorage.getItem("selected-user")).id,
          userEmail: JSON.parse(localStorage.getItem("selected-user"))?.email,
          state: "INREVIEW",
          userRole: JSON.parse(localStorage.getItem("selected-user")).role,
          lead: JSON.parse(localStorage.getItem("selected-user")).lead,
          date: new Date(),
        };
        formValues.history.push(tempHistory);
        formValues["State"] = "INREVIEW";
       
        
      }
    }
    formValues["source"] = "FireCrowd";

    if (
      JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.web !== null || (JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.others?.typeOfProject==="Standalone_Application")
    ) {
      formValues["platformDetails"] = [webplatform];
    }
    if (
      JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.mobile !== null|| (JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.others?.typeOfProject!=="Standalone_Application")
    ) {
      formValues["platformDetails"] = [mobileplatform];
    }
    if (
      JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.webAndMobile !== null
    ) {
      if (changePlatfrom === false) {
        if (webplatform === "" && mobileplatform !== "") {
          formValues["platformDetails"] = [mobileplatform];
        }
        if (mobileplatform === "" && webplatform !== "") {
          formValues["platformDetails"] = [webplatform];
        }
        if (mobileplatform !== "" && webplatform !== "") {
          formValues["platformDetails"] = [webplatform, mobileplatform];
        }
      } else {
        if (webplatform === "" && mobileplatform !== "") {
          formValues["platformDetails"] = [mobileplatform];
        }
        if (mobileplatform === "" && webplatform !== "") {
          formValues["platformDetails"] = [webplatform];
        }
        if (mobileplatform !== "" && webplatform !== "") {
          formValues["platformDetails"] = [webplatform, mobileplatform];
        }
      }
    }

    
    formValues["Reason"] = "";
    formValues["allowTestersToEdit"] = "false";

    data.defectDetails = formValues;
    TemplateData.defectDetails.map((obj) => {
      if (
        obj.type === "date" &&
        obj.label !== "Created On" &&
        obj.label !== "Modified On"
      ) {
        data.defectDetails[obj.label] = dateFormat(
          data.defectDetails[obj.label],
          obj.dateFormat
        );
      }
    });

    data.state = formValues["State"];
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    newFilesToBeUploaded.forEach((data) => {
      formData.append("file", data?.file);
    });
    formData.append("email", email);
  
    try {
      if (modalType === ADD) {
        if (mobileplatform === "" && webplatform === "") {
          props.MyAlert.info("Platform details are required to create defect");
          props.CloseCreateDefectModal(true);
        } else {
          resetProgress();
          startOrResumeProgress({
            stopAt: 100,
            intervalDuration: 80,
          });
          let response = await createDefectReq(
            formData,
            projectId,
            licenceID,
            email
          );
          console.log("response",response)
          if (response.status === 200) {
       
            props.MyAlert.success("Defect successfully created");
            props.callDefects(0);
             resetProgress();
           props.CloseCreateDefectModal(false);
            
          }
        }
      } else {
        if (mobileplatform === "" && webplatform === "") {
          resetProgress();
          startOrResumeProgress({
            stopAt: 100,
            intervalDuration: 80,
          });
          props.MyAlert.info("Platform details are required to create defect");
          props.CloseCreateDefectModal(true);
        } else {
          let defectid = props?.selectedDefectData.defectDetails.ID;

         
          if (
            editfield === false &&
            editwebplatform === false &&
            editMobileplatform === false && 
            props?.selectedDefectData?.defectDetails?.Module===moduleValue
          ) {
           
            props.MyAlert.info(`No changes were done in ${defectid}`);
          } else {
            let delResponse;
            const fileIds = [];
            deletedFiles.forEach((fileData) => {
              if (fileData?.id) {
                fileIds.push(fileData.id);
              }
            });

            if (fileIds?.length > 0) {
              delResponse = await deleteMultipleFilesReq(
                projectId,
                licenceID,
                fileIds
              );
              console.log("delResponse", delResponse);
            }
            let response = await UpdateDefectReq(
              formData,
              projectId,
              licenceID,
              email,
              defectid
            );
            if (
              response.status === 200 &&
              response.data.successMessage === "Defect successfully Updated"
            ) {
              console.log("response",response)
              props.MyAlert.success(
                `"${defectid}" Defect successfully Updated`
              );
              props.callDefects(response?.data?.defect);
            }
            console.log("update defect id", response);
            props.CloseCreateDefectModal(false);
          }
          // props.CloseCreateDefectModal(false);
        }
      }
    } catch (err) {
      console.log("error");
    }
    setIsLoading(false);
    setEnableBtn(true);
    // props.CloseCreateDefectModal(false);
  };

  function createYupSchema(schema, config) {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  }

  const createValidationSchema = (allUsers) => {
    let defectTemplateData = TemplateData?.defectDetails;
    for (let i = 0; i < defectTemplateData?.length; i++) {
      let element = defectTemplateData[i];
      let validations = [];
      if (element?.label === "State" && modalType === ADD) {
        element.enable = NO;
      }

      if (FORM_FIELDS_ARRAY.includes(element?.type)) {
        if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
          element.validationType = "array";
        } else if (element?.type === DATE) {
          element.validationType = DATE;
        } else if (["Modified By", "Created By"].includes(element?.label)) {
          element.validationType = "object";
        } else {
          element.validationType = "string";
        }
      }
      if ([TEXTFIELD, TEXTBOX].includes(element.type)) {
        let validationObj = {
          type: "matches",
          params: [
            checkValidationForAlphanumericSpecialCharacters,
            ALPHA_ERR_MSG,
          ],
        };
        validations.push(
          validationObj,
          {
            type: "trim",
            params: [NO_SPACE_AT_START_AND_END_MSG],
          },
          {
            type: "strict",
            params: [true],
          }
        );
        if (element?.label === "Summary") {
          validations.push(
            validationObj,
            {
              type: "max",
              params: [250, `Input cannot be more than 250 characters`],
            },
            {
              type: "min",
              params: [3, `Input cannot be less than 3 characters`],
            }
          );
        }
      }
      if (element?.minLength) {
        let validationObj = {
          type: "min",
          params: [
            element.minLength,
            `Input cannot be less than ${element.minLength} characters`,
          ],
        };
        validations.push(validationObj);
      }
      if (element?.maxLength) {
        let validationObj = {
          type: "max",
          params: [
            element.maxLength,
            `Input cannot be more than ${element.maxLength} characters`,
          ],
        };
        validations.push(validationObj);
      }
      if (element?.mandatory === YES) {
        let validationObj = {
          type: "required",
          params: [REQUIRED_ERR_MSG],
        };
        validations.push(validationObj);

        if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
          let validationObj = {
            type: "min",
            params: [1, REQUIRED_ERR_MSG],
          };
          validations.push(validationObj);
        }
      }
      if (element.type === LINK) {
        let validationObj = {
          type: "url",
          params: [VALID_URL],
        };
        validations.push(validationObj);
      }
      element.validations = validations;
      element.id = element.label;
    }
    createFormikSchema(defectTemplateData, allUsers);
  };

  function createFormikSchema(defectTemplateData, allUsers) {
    const { defectDetails } = props?.selectedDefectData;

    defectTemplateData?.forEach((item) => {
      if (item?.label === "State") {
        if (modalType === ADD) {
          // item.options =
          //   defectMgmtData?.defect_life_cycle_templates[0]?.states || [];
        } else if (modalType === EDIT) {
          // const nextState = handleDynamicStateValues(defectTemplateData.state, defectMgmtData?.defect_life_cycle_templates[0]?.state_transitions)
        }
        if (modalType === ADD) {
          tempInitialValues[item.label] = item?.options?.[0] || "";
        } else if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = "";
        }
      } else if (item?.label === "Assign to") {
        item.options = allUsers;
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label]?.id;
        } else {
          tempInitialValues[item.label] = "";
        }
      } else if (item?.label?.toLowerCase() === "description") {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        }
        // else if (failedSteps || envDetails) {
        //   tempInitialValues[item.label] = `{"blocks":${getDefectDescription(failedSteps, envDetails)},"entityMap":{}}`;
        // }
        else {
          tempInitialValues[item.label] = "";
        }
      } else if (item?.label?.toLowerCase() === "module") {
        if (selectedModule) {
          const [defaultCheckedNodeName, defaultCheckedNodeId] =
            selectedModule.split(":");
          setDefaultSelectedModule([
            { key: "key", value: defaultCheckedNodeId || "" },
          ]);
          tempInitialValues[item.label] = defaultCheckedNodeName || "";
        } else {
          const [defaultCheckedNodeName, defaultCheckedNodeId] =
            defectDetails && defectDetails[item.label]
              ? defectDetails[item.label].split(":")
              : ["", ""];
              setModuleValue( defectDetails && defectDetails[item.label]?defectDetails[item.label]:"")
          setDefaultSelectedModule([
            { key: "key", value: defaultCheckedNodeId || "" },
          ]);
          tempInitialValues[item.label] = defaultCheckedNodeName || "";
        }
      } else if ([CHECKBOX, ATTACHMENT].includes(item?.type)) {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = [];
        }
      } else if ([TEXTFIELD, TEXTBOX].includes(item?.type)) {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = item?.defaultValue || "";
        }
      } else if (item?.type === DATE) {
        if (defectDetails && defectDetails[item.label]?.length > 0) {
          const myDate = moment(
            defectDetails[item.label],
            item?.dateFormat && typeof item.dateFormat === "string"
              ? item?.dateFormat?.toUpperCase()
              : "DD-MM-YYYY"
          ).toDate();
          if (myDate instanceof Date && !isNaN(myDate)) {
            tempInitialValues[item.label] = myDate;
          } else {
            tempInitialValues[item.label] = "";
          }
        } else {
          tempInitialValues[item.label] = "";
        }
      } else {
        tempInitialValues[item.label] =
          (defectDetails && defectDetails[item.label]) || "";
      }
    });
    setInitialValues(tempInitialValues);
    setDefectTemplateFields(defectTemplateData);
    const yepSchema = defectTemplateData.reduce(createYupSchema, {});
    tempValidateSchema = yup.object().shape(yepSchema);
    setValidateSchema(tempValidateSchema);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validateSchema,
    onSubmit: (values) => {
      handleOnSubmit(values);
    },
  });

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
      // position:"inherit",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "82.5%",
      transform: "translate(-50%, -50%)",
      width: "35%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

  const formatDate = (dateVal) => {
    return dateVal?.replaceAll("mm", "MM");
  };

  const handleClickBtn = (id) => {
    let htmlEle = document.getElementById(id);
    if (htmlEle) {
      htmlEle.click();
    }
    if (modalType === ADD) {
      setEditField(false);
    } else {
      setEditField(true);
    }
  };
  const moduleButtonField = (data, props, error) => {
    const treeNodeSelected = (nodeObj) => {
      if (
        `${nodeObj[0]?.data?.path}:${nodeObj[0]?.data?._key}` ===
        props?.values?.Module
      ) {
        console.log("treeNodeSelected yes");
      } else {
        console.log("treeNodeSelected NO");
        console.log(
          "gfhjkl;",
          `${nodeObj[0]?.data?.path}:${nodeObj[0]?.data?._key}`
        );
        console.log("props?.values?.Module", props?.values?.Module);
        console.log("props", props);
      }
      props.setFieldTouched("Module", true);
      setModuleTreeData(nodeObj);
      if (nodeObj?.length) {
        props.setFieldValue(
          "Module",
          `${nodeObj[0]?.data?.path}:${nodeObj[0]?.data?._key}`,
          true
        );
        setModuleValue(`${nodeObj[0]?.data?.path}:${nodeObj[0]?.data?._key}`)

      } else {
        props.setFieldValue("Module", "", true);
        setModuleValue("")
      }
    };
    console.log(" moduleButtonField", props);
    return (
      <div>
        <div>
          <DefectTreeWithRadioButton
            editDefect={modalType === EDIT || selectedModule}
            moduleName={props?.values?.Module}
            moduleSelection={true}
            individualTree={false}
            hideRootRadioBtn={true}
            data={moduleTree}
            operation={null}
            defaultCheckedNode={defaultSelectedModule}
            placeholder={"Select module"}
            nodeSelected={treeNodeSelected.bind(this)}
            hideElements={true}
            hideElementsBtn={true}
            buttonLabel="Module"
            moduleLevelScriptCount={moduleLevelScriptCount}
            defect={true}
          />
        </div>

        {props.errors.Module &&
          props.touched.Module &&
          props.values.Module === "" && (
            <p className="errorMessage">{props.errors.Module}</p>
          )}
      </div>
    );
  };

  const handleSearchableSelectOnChange = (value, label) => {
    formik.setFieldTouched(label, true);
    if (value) {
      formik.setFieldValue(label, value.value);
    } else {
      formik.setFieldValue(label, "");
    }
  };
  const handleDataChange = (e, props, id, type) => {
    if (type !== "checkbox") {
      formik.handleChange(e);
      if (modalType === ADD) {
        setEditField(false);
      } else {
        if (
          defectdata.defectDetails[e.target.name].toLowerCase() ===
          e.target.value.toLowerCase()
        ) {
          setEditField(false);
        } else {
          setEditField(true);
        }
        // setEditField(true)
      }
    } else {
      formik.handleChange(e);
      if (modalType === ADD) {
        setEditField(false);
      } else {
        if (e.target.checked === true) {
          if (
            defectdata.defectDetails[e.target.name].includes(e.target.value)
          ) {
            setEditField(false);
          } else {
            setEditField(true);
          }
        } else {
          setEditField(true);
        }
      }
    }
  };
  const handleDropdownChange = (e, props, label) => {
    if (modalType === ADD) {
      setEditField(false);
    } else {
      if (defectdata?.defectDetails[label] === e[0].value) {
        setEditField(false);
      } else {
        setEditField(true);
      }
      // setEditField(true)
    }
    formik.setFieldValue(label, e[0].value);
  };
  const validateFilesSize = (files) => {
    let fileSizeInBytes = 0;
    for (let i = 0; i < files?.length; i++) {
      fileSizeInBytes += files[i]?.sizeInBytes;
    }
    return fileSizeInBytes <= 31457280;
  };

  const validateFileAlreadyExists = (newUploadedFiles, files) => {
    for (let i = 0; i < newUploadedFiles.length; i++) {
      for (let j = 0; j < files.length; j++) {
        if (files[j].name === newUploadedFiles[i].name) {
          return true;
        }
      }
    }
    return false;
  };

  const validateFileExtension = (file, allowedFileTypes) => {
    const selectedFile = file?.target?.files[0] || file;
    const fileExtension =
      "." + selectedFile?.name?.split(".")?.pop()?.toLowerCase();
    const fileType = selectedFile?.type;
    return (
      allowedFileTypes[fileExtension] &&
      fileType === allowedFileTypes[fileExtension]
    );
  };

  const onLoad = (event, data, setFieldValue, value) => {
    const allFiles = [...tempUploadFiles];
    const existingFiles = [...value];
    const newUploadedFiles = event?.target?.files;
    const newFilesToBeUploadedCopy = [...newFilesToBeUploaded];
    if (existingFiles?.length + newUploadedFiles?.length > 5) {
      props.MyAlert.info(MAX_NO_OF_FILES_ALLOWED);
      return;
    }
    if (validateFileAlreadyExists(newUploadedFiles, allFiles)) {
      props.MyAlert.info(DUPLICATE_FILE);
      return;
    }
     if (!validateFilesSize(allFiles)) {
      props.MyAlert.info(MAX_FILE_SIZE_ALLOWED);
      return;
    }
    let file;
     let unsupportedFile = [];
    for (let i = 0; i < newUploadedFiles.length; i++) {
      file = newUploadedFiles[i];
      if (
        file &&
        validateFileExtension(
          newUploadedFiles[i],
          DEFECT_ATTACTMENT_FILE_ALLOWED
        )
      ) {
        const obj = {
          name: file?.name,
          sizeInBytes: file?.size,
        };
        allFiles.push(obj);
        existingFiles.push(obj);
        newFilesToBeUploadedCopy.push({
          name: file?.name,
          sizeInBytes: file?.size,
          file: file,
        });
      } else {
        unsupportedFile.push(newUploadedFiles[i]);
      }
    }

     if (unsupportedFile.length) {
       props?.MyAlert.warning(
         `${unsupportedFile?.length} unsupported file. Please check the file format.`
       );
     }
   
    setFieldValue(data?.label, existingFiles);
    setTempUploadFiles(allFiles);
    setNewFilesToBeUploaded(newFilesToBeUploadedCopy);

    event.target.value = "";
  };

  const defectMgmtDetailElements = (data, props, error, index) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <div className="flex ">
              <label className="defect_labels">
                {data ? (
                 
                    <div className="overflow-hidden overflow-ellipsis whitespace-nowrap w-64" title={data?.label}>
                      {data?.mandatory === YES ? (
                        <span className="text-red-400 mr-1">&#42;</span>
                      ) : null}
                      {data?.label}
                    </div>
                
                ) : null}
              </label>
            </div>
          </div>
          {data?.label === "Module" ? (
            moduleButtonField(data, props, error)
          ) : (
            <div className="mb-2">
              {defectMgmtComponents(data, props, error, index)}
            </div>
          )}
        </div>
      </>
    );
  };
  const navigateToUrl = (url, error) => {
    if (!error && url) {
      window.open(url, "_blank");
    }
  };
  const handleClickDatepickerIcon = (id) => {
    if (id) {
      document.getElementById(id).focus();
    }
    if (modalType === ADD) {
      setEditField(false);
    } else {
      setEditField(true);
    }
  };

  const deleteFile = (file) => {
    let files = [...tempUploadFiles];
    files = files.filter((fileVal) => {
      return file?.name !== fileVal?.name;
    });
    setTempUploadFiles(files);
    let newFilesToBeUploadedCopy = [...newFilesToBeUploaded];
    newFilesToBeUploadedCopy = newFilesToBeUploadedCopy.filter((fileVal) => {
      return file?.name !== fileVal?.name;
    });
    setNewFilesToBeUploaded(newFilesToBeUploadedCopy);
    const tempDeletedFiles = [...deletedFiles];
    tempDeletedFiles.push(file);
    setDeletedFiles(tempDeletedFiles);
    if (modalType === ADD) {
      setEditField(false);
    } else {
      setEditField(true);
    }
  };

  const getSearchableSelectedOption = (data, value) => {
    if (value) {
      const selectedOption = data?.options?.find((option) => option === value);

      return [
        { ...selectedOption, label: selectedOption, value: selectedOption },
      ];
    } else {
      return [];
    }
  };
  const defectMgmtComponents = (data, props, error) => {
    if (data && props) {
      const value = props?.values[data.id];
      console.log("props?.values[data.value]", value);
      const { id, type, enable, placeholder, maxLength, label } = data;
      let dropoptions = [];
      if (type === DROPDOWN) {
        data.options.map((option) => {
          dropoptions.push({ label: option, value: option });
        });
      }

      switch (type) {
        case TEXTFIELD:
          return enable === YES || data.id === "ID" ? (
            <div className="mt-3 user-input-container">
              <TextField
                title={value}
                fullWidth
                size="small"
                error={formik.errors[id] && formik.touched[id] ? true : false}
                className="w-64"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={data.id === "ID" ? true : false}
                type="text"
                autoComplete="off"
                name={id}
                id={id}
                placeholder={placeholder}
                onChange={(e) => handleDataChange(e, props, id, type)}
                helperText={
                  formik.errors[id] && formik.touched[id] ? (
                    <div className="defect-error -ml-2">{error}</div>
                  ) : (
                    ""
                  )
                }
                value={value}
                onBlur={(e) => {
                  formik.handleBlur(e);
                }}
              />
            </div>
          ) : (
            <label className="label-overflow testCase">
              {["Created By", "Modified By"].includes(label)
                ? value?.name || "--"
                : value || "--"}
            </label>
          );

        case TEXTBOX:
          return (
            <>
              {enable === YES ? (
                <div className="mt-2">
                  <TextareaAutosize
                    title={value}
                    name={id}
                    id={id}
                    onChange={(e) => handleDataChange(e, props, id, type)}
                    maxLength={maxLength}
                    value={value}
                    style={{ height: "60px" }}
                    className="w-full pl-2 textarea-content-style"
                    placeholder={placeholder}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                    }}
                  />
                  {maxLength ? (
                    <div className="flex justify-between">
                      <div>
                        {formik.errors[id] && formik.touched[id] && (
                          <p className="MuiFormHelperText-root Mui-error mt-1 defect-error ml-2">
                            {error}
                          </p>
                        )}
                      </div>
                      <div className="mt-1 text-sm text-gray-500 text-right mr-2">
                        {value ? value.length : 0}/{maxLength}
                      </div>
                    </div>
                  ) : (
                    <div>
                      {formik.errors[id] && formik.touched[id] && (
                        <p className="MuiFormHelperText-root Mui-error mt-1 defect-error ml-2">
                          {error}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <label className="label-overflow testCase">
                  {value || "--"}
                </label>
              )}
            </>
          );

        case TEXTEDITOR:
          return (
            <>
              <div className="mt-2">
                <FormikProvider value={formik}>
                  <Field name={id}>
                    {({ form, field }) => {
                      const { setFieldValue } = form;
                      const { value } = field;
                      return (
                        <div>
                          {value !== undefined && (
                            <RichTextEditor
                              readOnly={enable === "no" ? true : false}
                              toolbarHidden={enable === "no" ? true : false}
                              convertedContent={value || ""}
                              setConvertedContent={(content) => {
                                if (content) {
                                  // console.log("content",content)
                                  const validateContent = JSON.parse(content);
                                  // console.log("validateContent",validateContent)
                                  if (
                                    validateContent?.blocks?.length === 1 &&
                                    validateContent?.blocks[0]?.text === ""
                                  ) {
                                    setFieldValue(id, "");
                                  } else {
                                    setFieldValue(id, content);
                                  }
                                } else {
                                  setFieldValue(id, "");
                                }
                                if (modalType === ADD) {
                                  setEditField(false);
                                } else {
                                  setEditField(true);
                                }
                              }}
                            />
                          )}
                          <div>
                            {formik.errors[id] && formik.touched[id] && (
                              <p className="MuiFormHelperText-root Mui-error mt-1 defect-error ml-2 mt-2">
                                {error}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                </FormikProvider>
              </div>
            </>
          );

        case RADIOBUTTON:
          return (
            <>
              <div className="w-100 mt-2">
                <div className="grid grid-cols-2 gap-2">
                  {data?.options?.map((option, index) => (
                    <div key={index} className="flex" title={option}>
                      <input
                        type="radio"
                        onChange={(e) => handleDataChange(e, props, id, type)}
                        className="mr-2"
                        name={id}
                        id={id}
                        value={option}
                        checked={option === value}
                      />
                      <label className="text-xs overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
                {formik.errors[id] && formik.touched[id] && (
                  <p className="MuiFormHelperText-root Mui-error defect-error mt-1 ml-1">
                    {error}
                  </p>
                )}
              </div>
            </>
          );

        case LINK:
          return (
            <>
              <div className="flex mt-3 user-input-container">
                <TextField
                  title={value}
                  fullWidth
                  size="small"
                  error={formik.errors[id] && formik.touched[id] ? true : false}
                  className="w-56 link-placeholder-text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  autoComplete="off"
                  name={id}
                  id={id}
                  placeholder="Attached Link will be shown here if any URL is added"
                  onChange={(e) => handleDataChange(e, props, id, type)}
                  helperText={
                    formik.errors[id] && formik.touched[id] ? (
                      <div className="defect-error -ml-2">{error}</div>
                    ) : (
                      ""
                    )
                  }
                  value={value}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                />
              </div>
            </>
          );

        case DROPDOWN:
          return (
            <>
              <div className={label === "Assign to" ? "" : "mt-2"}>
                <FormControl variant="standard">
                  {label !== "Assign to" ? (
                    <div className="w-64">
                      <SelectDropdown
                        data={data?.options?.map((option) => ({
                          ...option,
                          label: option,
                          value: option,
                        }))}
                        backspaceDelete={false}
                        disabled={enable && enable === NO ? true : false}
                        placeholder={placeholder ? placeholder : ""}
                        selectedData={getSearchableSelectedOption(data, value)}
                        onChange={(e) => {
                          handleDropdownChange(e, props, label);
                          console.log(data, placeholder);
                        }}
                      />
                    </div>
                  ) : (
                    <Select
                      disabled={enable && enable === NO ? true : false}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                      id={id}
                      name={id}
                      onChange={(e) => handleDataChange(e, props, id, type)}
                      value={value || ""}
                      defaultValue=""
                      // fullwidth
                      className="w-64"
                      displayEmpty={true}
                      renderValue={(value) =>
                        value ? (
                          value
                        ) : (
                          <span className="opacity-50">
                            {placeholder || ""}
                          </span>
                        )
                      }
                    >
                      {data?.options?.map((option, index) => (
                        <MenuItem
                          title={option}
                          key={index}
                          className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 text-xs w-64 overflow-hidden overflow-ellipsis whitespace-nowrap manual-test-case-select"
                          value={option}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {formik.errors[id] && formik.touched[id] && (
                    <p className="MuiFormHelperText-root Mui-error defect-error ml-2 mt-2">
                      {error}
                    </p>
                  )}
                </FormControl>
              </div>
            </>
          );

        case DATE:
          return (
            <>
              <div>
                <div className="defect-datepicker flex">
                  <FormikProvider value={formik}>
                    <Field name={id}>
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;
                        return (
                          <DatePickerView
                            readOnly={
                              ["Created On", "Modified On"].includes(label)
                                ? true
                                : false
                            }
                            className="datepicker_field"
                            id={id}
                            {...field}
                            selected={value}
                            autoComplete="off"
                            onChange={(val) => {
                              setFieldValue(id, val);
                              if (modalType === ADD) {
                                setEditField(false);
                              } else {
                                if (
                                  defectdata.defectDetails[id] ===
                                  dateFormat(val, data?.dateFormat)
                                ) {
                                  setEditField(false);
                                } else {
                                  setEditField(true);
                                }
                              }
                            }}
                            onKeyDown={(e) => e.preventDefault()}
                            dateFormat={
                              data?.dateFormat &&
                              typeof data?.dateFormat === "string"
                                ? formatDate(data?.dateFormat)
                                : "dd/MM/yyyy"
                            }
                            maxDate={new Date()}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                          />
                        );
                      }}
                    </Field>
                  </FormikProvider>
                  <div
                    className="mr-2"
                    onClick={() => handleClickDatepickerIcon(id)}
                  >
                    <CalendarTodayOutlined className="mt-1" />
                  </div>
                </div>
              </div>
              <div className="text-xs opacity-50 ml-1">
                [
                {data?.dateFormat && Array.isArray(data.dateFormat)
                  ? data.dateFormat.join(",")
                  : data?.dateFormat}
                ]
              </div>
              {formik.errors[id] && formik.touched[id] && (
                <p className="MuiFormHelperText-root Mui-error defect-error mt-1 ml-1">
                  {error}
                </p>
              )}
            </>
          );

        case CHECKBOX:
          return (
            <>
              <div className="w-100 mt-2">
                <div className="grid grid-cols-2 gap-2">
                  {data?.options?.map((option, index) => (
                    <div key={index} className="flex" title={option}>
                      <input
                        type="checkbox"
                        className="mr-2"
                        name={id}
                        id={id}
                        value={option}
                        onChange={(e) => {
                          handleDataChange(e, props, id, type);
                        }}
                        checked={
                          value && value?.length > 0 && value.includes(option)
                        }
                      />
                      <label className="text-xs overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
                {formik.errors[id] && formik.touched[id] && (
                  <p className="MuiFormHelperText-root Mui-error defect-error mt-2 ml-1">
                    {error}
                  </p>
                )}
              </div>
            </>
          );

        case ATTACHMENT:
          return (
            <>
              <div className="mt-1">
                <FormikProvider value={formik}>
                  <Field name={id}>
                    {({ form, field }) => {
                      const { setFieldValue } = form;
                      const { value } = field;
                      return (
                        <>
                          <button
                            onChange={(event) =>
                              onLoad(event, data, setFieldValue, value)
                            }
                            className="hidden"
                          >
                            <input type="file" id={id} multiple />
                          </button>
                          {value?.length > 0 ? (
                            value?.map((option, index) => (
                              <div key={index} className="flex flex-row">
                                <div className="flex items-center">
                                  <div title={option?.name}>
                                    <p
                                      className="text-sm hyper_link_color-common
                                overflow-hidden
                                overflow-ellipsis
                                whitespace-nowrap
                                w-36"
                                    >
                                      {option?.name}
                                    </p>
                                  </div>
                                  <div title="Delete">
                                    <DeleteOutline
                                      color="primary"
                                      className="h-6 w-6 mt-1 mr-2 origin-center cursor-pointer"
                                      onClick={() => {
                                        deleteFile(option);
                                        const filteredValue = value?.filter(
                                          (file) => {
                                            return file?.name !== option?.name;
                                          }
                                        );
                                        setFieldValue(id, filteredValue);
                                      }}
                                    />
                                  </div>

                                  {value?.length > 0 && value?.length < 5 ? (
                                    <>
                                      {index === value?.length - 1 && (
                                        <AddCircle
                                          color="primary"
                                          className="h-6 w-6 mt-1 mr-2 origin-center cursor-pointer"
                                          onClick={() => handleClickBtn(id)}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="flex flex-row">
                              <div
                                className="flex items-center cursor-pointer"
                                onClick={() => handleClickBtn(id)}
                              >
                                {label === "Attach multiple images" ? (
                                  <Multiplefiles />
                                ) : label === "Attach Video" ? (
                                  <VideoIcon />
                                ) : (
                                  <AttachmentIconBlue className="h-6 w-6 mr-2 origin-center" />
                                )}

                                <p
                                  aria-disabled={true}
                                  className="text-sm hyper_link_color-common ml-1"
                                >
                                  Attach
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    }}
                  </Field>
                </FormikProvider>
              </div>
              {formik.errors[id] && formik.touched[id] && (
                <p className="MuiFormHelperText-root Mui-error defect-error mt-2 ml-1">
                  {error}
                </p>
              )}
            </>
          );

        default:
          return (
            <>
              <label className="label-overflow testCase"></label>
            </>
          );
      }
    }
  };
  const testCaseDetailSection = () => {
    return (
      <>
        <div className="">
          <div className="">
            {defectTemplateFields &&
              defectTemplateFields?.length > 0 &&
              defectTemplateFields?.map((data, index) => {
                let error =
                  formik.errors.hasOwnProperty(data.id) &&
                  formik.errors[data.id];
                if (modalType === ADD) {
                  return [
                    "ID",
                    "Modified By",
                    "Created By",
                    "Created On",
                    "Modified On",
                    "Assign to",
                    "State",
                    // "Module",
                  ].includes(data?.label) ? (
                    <></>
                  ) : [TEXTEDITOR, TEXTBOX].includes(data?.type) ? (
                    defectMgmtDetailElements(data, formik, error, index)
                  ) : (
                    defectMgmtDetailElements(data, formik, error, index)
                  );
                } else {
                  return [
                    "Modified By",
                    "Created By",
                    "Created On",
                    "Modified On",
                    "Assign to",
                    "State",
                    // "Module",
                  ].includes(data?.label) ? (
                    <></>
                  ) : [TEXTEDITOR, TEXTBOX].includes(data?.type) ? (
                    defectMgmtDetailElements(data, formik, error, index)
                  ) : (
                    defectMgmtDetailElements(data, formik, error, index)
                  );
                }
              })}
          </div>
          <div className="my-5">
            {JSON.parse(localStorage.getItem("selected-project")).project
              ?.projectType?.webservice !== null && <Addplatform />}
          </div>
        </div>
      </>
    );
  };
  function CloseWarningStatus(state) {
    setStatusWarnOpen(state);
  }
  function CloseDefectReasonModal(state) {
   
    setDefectReasonModalOpen(state);
  }

  function updatetabeldata(data){
    props?.callDefects(data);
    props?.CloseCreateDefectModal(false);
  }

  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px defectpostion"
    >
      <div className="flex justify-between rounded-tl-xl defect-template-modal-heading h-10 p-2">
        <div>
          {modalType === ADD
            ? "Create Defect"
            : `Edit Defect-${props.selectedDefectData.defectDetails.ID}`}
        </div>
        

        <button
          onClick={() => {
            setOpenModal(false);
            props.CloseCreateDefectModal(false);
          }}
          type="button"
          className="modal-close-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      <div className="main-content overflow-y-scroll" id="journal-scroll">
        {/* <div className="flex">
          <div onClick={()=>{
            ApprovedOrRejectDefect(formik.values,"approved")
           
          }}>Approved</div>
          <div onClick={()=>{ ApprovedOrRejectDefect(formik.values,"rejected")}}>Rejected</div>

        </div> */}
        <div className={`flex justify-end mr-4 mt-2 ${modalType!==ADD && (JSON.parse(localStorage.getItem("selected-user")).role!=="TESTER" && JSON.parse(localStorage.getItem("selected-user")).role!=="PROJECT_OWNER")?"block":"hidden"}`}>
          <div className="ml-2 cursor-pointer" title="Approved"><ApprovedShortBtn onClick={()=>{
            ApprovedOrRejectDefect(formik.values,"approved")
           
          }}/></div>
          <div className="ml-2 cursor-pointer" title="Rejetced"><RejectedShortBtn onClick={()=>{
            ApprovedOrRejectDefect(formik.values,"rejected")
           
          }}/></div>
        </div>
        <div className="px-3 pb-8">{testCaseDetailSection()}</div>
        <div className="px-3 pb-8">
          <Addplatform
            platform={platformdetails}
            webplatform={webplatform}
            mobileplatform={mobileplatform}
            getplatformdetails={getplatformdeatils}
            platformselect={platformselect}
          />
        </div>
        {isLoading && <CommonLoader />}

        <div className="py-2 px-3 flex justify-end gap-4 user-create-button z-50">
          <button
            onClick={() => {
              setOpenModal(false);
              props.CloseCreateDefectModal(false);
            }}
            type="button"
            className="w-24 gray-btn ml-3 button"
          >
            Cancel
          </button>
          <button
            className={
              enablebtn
                ? "w-24 primary-btn button"
                : "w-24 primary-btn button opacity-50"
            }
            onClick={() => {
              if (enablebtn) {
                formik.handleSubmit();
              }
            }}
          >
            {modalType === ADD ? "Create" : "Update"}
          </button>
        </div>
        {
          statusWarnOpen && <ChangeStatusWarning
          CloseWarningStatus={CloseWarningStatus}
          updatetabeldata={updatetabeldata}
          bugHistoryId={bugHistoryId}
          dataForApprove={dataForApprove}
          MyAlert={props?.MyAlert}
          />
        }
        {
          defectReasonModalOpen && <DefectReasonModal
          CloseDefectReasonModal={CloseDefectReasonModal}
          updatetabeldata={updatetabeldata}
          dataForApprove={dataForApprove}
          MyAlert={props.MyAlert}
          />
        }
      </div>
    </Modal>
  );
}
export default CreateDefect;
