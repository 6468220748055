import { post } from "jquery";
import httpConnection from "./http-connection.js";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const APP_MANAGEMENT_BASE_URL = process.env.REACT_APP_APP_MANAGEMENT_BASE_URL;
const WEBSERVICE_BASE_URL = process.env.REACT_APP_WEBSERVICE_BASE_URL;
const FIREFLINK_TREE_URL = process.env.REACT_APP_FIREFLINK_TREE_URL;
const FIREFLINK_BASE_URL = process.env.REACT_APP_FIREFLINK_BASE_URL;
const FIREFLINK_TESTCASE_URL = process.env.REACT_APP_FIREFLINK_TESTCASE_URL;
const FIREFLINK_BASE_TREE_URL = process.env.REACT_APP_FIREFLINK_BASE_TREE_URL;
// const baseurl="http://30.30.30.87:8202"
const TEMP_PLATFORM_URL = process.env.REACT_APP_PLATFORM_IP;
const SIGNOUT_URL = process.env.REACT_APP_SIGNOUT;
const REPORTS_URL = process.env.REACT_APP_REPORTS_BASE_URL;

export const signUpRequest = (payload) =>
  httpConnection.post(
    `${APP_MANAGEMENT_BASE_URL}/appmanagement/optimize/v1/public/user/signup`,
    payload,
    { type: "signup" }
  );
export const loginInRequest = (payload) =>
  httpConnection.post(
    `${APP_MANAGEMENT_BASE_URL}/appmanagement/optimize/v1/public/user/signin`,
    payload,
    { type: "signin" }
  );
export const activateEmail = (payload) =>
  httpConnection.post(
    `${APP_MANAGEMENT_BASE_URL}/appmanagement/optimize/v1/public/user/activation/activate`,
    payload,
    { type: "signup" }
  );
export const existEmailRequest = (payload) =>
  httpConnection.post(
    `${APP_MANAGEMENT_BASE_URL}/appmanagement/optimize/v1/public/user/email/exists`,
    payload,
    { type: "signup" }
  );
export const defaultCountry = () =>
  httpConnection.get(`https://api.country.is/`);

export const resetActivationLink = (payload) =>
  httpConnection.post(
    `${APP_MANAGEMENT_BASE_URL}/appmanagement/optimize/v1/public/user/activation/resend`,
    payload,
    { type: "signup" }
  );
export const forgotPasswordRequest = (payload) =>
  httpConnection.post(
    `${APP_MANAGEMENT_BASE_URL}/appmanagement/optimize/v1/public/user/send-change-pwd-link`,
    payload,
    { type: "signin" }
  );
export const signInSiteKey = (payload) =>
  httpConnection.post(
    `${APP_MANAGEMENT_BASE_URL}/appmanagement/optimize/v1/public/user/site-key`,
    payload,
    { type: "signup" }
  );
export const changeActivePhoneNumber = (payload) =>
  httpConnection.post(
    `${APP_MANAGEMENT_BASE_URL}/appmanagement/optimize/v1/public/user/change-phone`,
    payload,
    { type: "signup" }
  );
export const changePasswordRequest = (payload) =>
  httpConnection.post(
    `${APP_MANAGEMENT_BASE_URL}/appmanagement/optimize/v1/public/user/change-password`,
    payload,
    { type: "signin" }
  );

export const getAllProjectRequest = (mailID) =>
  httpConnection.get(`${BASE_URL}/v1/users/projects/${mailID}`);
export const getAllProjectAdminRequest = () =>
  httpConnection.get(`${BASE_URL}/v1/projects/`);
export const getProjectDetails = (projectID) =>
  httpConnection.get(`${BASE_URL}/v1/projects/${projectID}`);
export const downloadFileRequest = (filePath) =>
  httpConnection.get(`${BASE_URL}/v1/public/file?filePath=${filePath}`);
export const deleteProjectRequest = (projectID, hasfiles) =>
  httpConnection.delete(
    `${BASE_URL}/v1/projects/?projectId=${projectID}&hasFiles=${hasfiles}`
  );
export const getUsersByUserId = (projectID, userId) =>
  httpConnection.get(`${BASE_URL}/v1/user/${projectID}/users/${userId}`);
export const getAllUsersList = (projectID, page, size) =>
  httpConnection.get(
    `${BASE_URL}/v1/users/${projectID}?page=${page}&size=${size} `
  );
export const getAllUsersListhierarchy = (projectID, email, page, size) =>
  httpConnection.get(
    `${BASE_URL}/v1/users/hierarchy/${projectID}/${email}?page=${page}&size=${size} `
  );
export const getAllUsersListhierarchyInReports = (projectID, email) =>
  httpConnection.get(`${BASE_URL}/v1/users/hierarchy/${projectID}/${email} `);

export const createProject = (payload, roleID) =>
  httpConnection.post(`${BASE_URL}/v1/projects`, payload, { type: "formdata" });
export const editProject = (payload, roleID) =>
  httpConnection.put(`${BASE_URL}/v1/projects`, payload, { type: "formdata" });

export const getUserByEmailId = (emailId) =>
  httpConnection.get(
    `${APP_MANAGEMENT_BASE_URL}/appmanagement/optimize/v1/user/emailId?emailId=${emailId}`
  );

export const getUserPrivilege = (role) =>
  httpConnection.get(`${BASE_URL}/v1/privilege/${role}`);
export const getMyRole = (emailId) =>
  httpConnection.get(`${BASE_URL}/v1/admins/${emailId}`);

//user api's
export const createUser = (payload) =>
  httpConnection.post(`${BASE_URL}/v1/users/`, payload);
export const getAllUserRoles = (role) =>
  httpConnection.get(`${BASE_URL}/v1/user/users/${role} `);
export const getAlldependentUser = (projectId, leadEmail) =>
  httpConnection.get(
    `${BASE_URL}/v1/users/dependents/${projectId}/${leadEmail}`
  );
export const getAllLeadUser = (projectId, role, email) =>
  httpConnection.get(
    `${BASE_URL}/v1/users/?projectId=${projectId}&role=${role}&leadEmail=${email} `
  );

export const getAllLeadUseroftestcase = (projectId, email) =>
  httpConnection.get(
    `${BASE_URL}/v1/users/dependent/users/${projectId}/${email}`
  );

export const removeUserReq = (payload, projectId, emailid) =>
  httpConnection.delete(
    `${BASE_URL}/v1/users/${projectId}?editorEmail=${emailid}`,
    { data: payload }
  );
export const editUser = (payload) =>
  httpConnection.put(`${BASE_URL}/v1/users/`, payload);
export const getUserHierarchyReq = () =>
  httpConnection.get(`${BASE_URL}/v1/hierarchy`);
//Defect template
export const createDefectTemplateRequest = (
  payload,
  passProjectId,
  licenseId
) =>
  httpConnection.post(
    `${WEBSERVICE_BASE_URL}/project/optimize/v1/defectDetailsTemplate/`,
    payload,
    { headers: { projectId: passProjectId, licenseId: licenseId } }
  );
export const getAllDefectTemplate = (projectId, licenseId) =>
  httpConnection.get(
    `${WEBSERVICE_BASE_URL}/project/optimize/v1/defectDetailsTemplate/`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const updateDefaultDefectTemplateRequest = (
  payload,
  templateId,
  projectId,
  licenseId
) =>
  httpConnection.put(
    `${WEBSERVICE_BASE_URL}/project/optimize/v1/defectDetailsTemplate/?templateId=${templateId}`,
    payload,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const deleteDefectTemplateRequest = (
  temp_id,
  passProjectId,
  licenseId
) =>
  httpConnection.delete(
    `${WEBSERVICE_BASE_URL}/project/optimize/v1/defectDetailsTemplate/${temp_id}`,
    { headers: { projectId: passProjectId, licenseId: licenseId } }
  );
export const updateDefectTemplateRequest = (
  payload,
  projectId,
  temId,
  licenseId
) =>
  httpConnection.put(
    `${WEBSERVICE_BASE_URL}/project/optimize/v1/defectDetailsTemplate/${temId}`,
    payload,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const getTemplateById = (projectId, templateId, licenseId) =>
  httpConnection.get(
    `${WEBSERVICE_BASE_URL}/project/optimize/v1/defectDetailsTemplate/${templateId}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const getAllDefectFromFireFlink = (projectId, licenseId) =>
  httpConnection.get(
    `http://devbackend.fireflink.com/project/optimize/v1/defectLifecycleTemplate/config`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
//Platform
export const getAllPlatformReq = (projectId, emailId) =>
  httpConnection.get(
    `${BASE_URL}/v1/config/platform/all/${projectId}/${emailId}`
  );
// change the url latter--------------------------to BASE_URL---
export const capturePlatformDetails = (UserAgent) =>
  httpConnection.get(`${TEMP_PLATFORM_URL}/v1/public/platformDetails/`, {
    headers: { UserAgent: UserAgent },
  });
// change the url latter--------------------------to BASE_URL---
export const postPlatformDetails = (payload, EncryptedString) =>
  httpConnection.post(`${TEMP_PLATFORM_URL}/v1/public/platform/`, payload, {
    headers: { "Encrypted-String": EncryptedString },
  });
export const platFormEmailSend = (projectId, UserId, emailId) =>
  httpConnection.get(
    `${BASE_URL}/v1/email/send/${projectId}/${UserId}/${emailId}`
  );
export const editPlatformName = (projectId, emailId, payload) =>
  httpConnection.put(
    `${BASE_URL}/v1/config/platform/${projectId}/${emailId}`,
    payload
  );
export const setDefaultPlatforms = (projectId, emailId, payload) =>
  httpConnection.patch(
    `${BASE_URL}/v1/config/platform/${projectId}/${emailId}`,
    payload
  );
//Defect
export const getDefectLifecycleReq = (projectId, licenseId, email) =>
  httpConnection.get(
    `${APP_MANAGEMENT_BASE_URL}/project/optimize/v1/defectLifecycleTemplate/?action=config`,
    { headers: { projectId: projectId, licenseId: licenseId, emailId: email } }
  );
export const createDefectReq = (payload, projectId, licenseId, emailId) =>
  httpConnection.post(
    `${BASE_URL}/v1/defects/`,
    payload,
    {
      headers: { projectId: projectId, licenseId: licenseId, emailId: emailId },
    },
    { type: "formdata" }
  );
export const myDefectListReq = (projectId, licenseId, emailId) =>
  httpConnection.get(`${BASE_URL}/v1/defects/`, {
    headers: { projectId: projectId, licenceId: licenseId, email: emailId },
  });
export const UpdateDefectReq = (
  payload,
  projectId,
  licenseId,
  emailId,
  defectId
) =>
  httpConnection.put(
    `${BASE_URL}/v1/defects/${defectId}`,
    payload,
    {
      headers: { projectId: projectId, licenseId: licenseId, emailId: emailId },
    },
    { type: "formdata" }
  );

export const getDefectsByUserReq = (projectId, licenseId, pageNo, pageSize) =>
  httpConnection.get(
    `${BASE_URL}/v1/defects/defectByUser?page=${pageNo}&size=${pageSize}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const getDefectsByStateReq = (
  projectId,
  licenseId,
  status,
  pageNo,
  pageSize
) =>
  httpConnection.get(
    `${BASE_URL}/v1/defects/defectByStatus?page=${pageNo}&size=${pageSize}`,
    { headers: { projectId: projectId, licenseId: licenseId, state: status } }
  );
export const getDefectsByEmailIdReq = (
  projectId,
  licenseId,
  emailId,
  pageNo,
  pageSize
) =>
  httpConnection.get(
    `${BASE_URL}/v1/defects/defectByEmailId?page=${pageNo}&size=${pageSize}`,
    {
      headers: {
        projectId: projectId,
        licenseId: licenseId,
        userEmail: emailId,
      },
    }
  );
export const getAllDefectsReq = (
  projectId,
  licenseId,
  userId,
  pageNo,
  pageSize
) =>
  httpConnection.get(
    `${BASE_URL}/v1/defects/?page=${pageNo}&size=${pageSize}`,
    { headers: { projectId: projectId, licenseId: licenseId, userId: userId } }
  );
export const getUserDetailsByEmailID = (projectId, email) =>
  httpConnection.get(`${BASE_URL}/v1/users/${projectId}/${email}`);

export const downloadDefectFileReq = (
  filename,
  flinkprojectid,
  licenseId,
  defectId
) =>
  httpConnection({
    method: "GET",
    url: `${REPORTS_URL}/v1/jira/download`,
    responseType: "blob",
    headers: {
      flinkprojectid: flinkprojectid,
      licenseId: licenseId,
      defectId: defectId,
      attachmentName: filename,
    },
  });

export const deleteMultipleFilesReq = (projectId, licenseId, payload) =>
  httpConnection.delete(
    `${APP_MANAGEMENT_BASE_URL}/project/optimize/v1/defects/`,
    { data: payload, headers: { projectId: projectId, licenseId: licenseId } }
  );

// export const getReportsData=(projectId,licenseId)=>httpConnection.get(`${REPORTS_URL}/v1/reports/`, { headers: { flinkProjectID:projectId, license:licenseId} });
export const getReportsData = (projectId, licenseId) =>
  httpConnection.get(`${REPORTS_URL}/v1/defectReports/`, {
    headers: { flinkProjectID: projectId, license: licenseId },
  });
export const getDefectsData = (
  projectId,
  licenseId,
  defectFilter,
  page,
  size,
  state
) =>
  httpConnection.post(
    `${REPORTS_URL}/v1/reports/defect-details?page=${page}&size=${size}`,
    defectFilter,
    { headers: { projectId: projectId, licenseId: licenseId, State: state } }
  );
export const getReportsClientData = (projectId, licenseId, status) =>
  httpConnection.get(`${REPORTS_URL}/v1/reports/client-details`, {
    headers: { projectId: projectId, licenseId: licenseId, status: status },
  });

// Project Domain
export const getALLDomainReq = () =>
  httpConnection.get(`${BASE_URL}/v1/domain/`);

//Certificate API
// export const getCertificate=(projectId,userId)=>httpConnection.get(`${BASE_URL}/v1/projects/file?filePath=${projectId}/Certificate/Certificate_${userId}.pdf`)

// export const getCertificateimg=(projectId,userId)=>httpConnection.get(`${BASE_URL}/v1/projects/file?filePath=${projectId}/Certificate/Certificate_${userId}.jpg`,{responseType: 'blob',})

export const getCertificateimg = (userId) =>
  httpConnection.get(`${BASE_URL}/v1/users/file/?email=${userId}`, {
    responseType: "blob",
  });

export const getCertificate = (userId) =>
  httpConnection.get(
    `${BASE_URL}/v1/public/file?filePath=Certificate/${userId}/Certificate_${userId}.pdf`
  );

export const UpdateExecutiveRepot = (payload) =>
  httpConnection.put(
    `${BASE_URL}/v1/projects/editDefectExecutiveReport`,
    payload,
    { type: "formdata" }
  );

export const UpdateDefectExecutiveReport = (payload) =>
  httpConnection.put(`${BASE_URL}/v1/projects/allowRejectedDefects`, payload, {
    type: "formdata",
  });

// Excle Download

export const DownLoadExcelFile = (
  key,
  fireFlinkProjectId,
  projectId,
  licenseId,
  file,
  state
) =>
  httpConnection({
    method: "GET",
    url: `${REPORTS_URL}/v1/reports/exportReport?${key}=1&filename=${file}`,
    responseType: "blob",
    headers: {
      flinkProjectID: fireFlinkProjectId,
      license: licenseId,
      projectId: projectId,
      state: state,
    },
  });

export const DownloadDefectExcleFile = (projectId, licenseId, state, payload) =>
  httpConnection({
    method: "POST",
    url: `${REPORTS_URL}/v1/reports/exportDefects`,
    data: payload,
    responseType: "blob",
    headers: { projectId: projectId, licenseId: licenseId, state: state },
  });
// TestCase
export const getTestcaseDetailsById = (
  moduleId,
  ScriptId,
  projectId,
  licenseId
) =>
  httpConnection.get(
    `${FIREFLINK_BASE_URL}/optimize/v1/scripts/modules/${moduleId}/script/${ScriptId}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const updateScriptReq = (
  moduleId,
  scriptId,
  payload,
  projectId,
  licenseId
) =>
  httpConnection.put(
    `${FIREFLINK_BASE_URL}/optimize/v1/scripts/modules/${moduleId}/script/${scriptId}?action=UPDATE`,
    payload,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

export const createTestcase = (payload, parentId, projectID, licenseId) =>
  httpConnection.post(
    `${BASE_URL}/v1/testcase/createScript/${parentId}/`,
    payload,
    { headers: { projectId: projectID, licenseId: licenseId } }
  );
export const getAllTestCaseTemplate = (action, projectId, licenseId) =>
  httpConnection.get(
    `${FIREFLINK_BASE_URL}/optimize/v1/scriptTemplate/${
      action ? "?action=" + action : ""
    }`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const updateDefaultTemplateRequest = (
  projectId,
  licenseId,
  payload,
  templateId
) =>
  httpConnection.put(
    `${FIREFLINK_BASE_URL}/optimize/v1/scriptTemplate/?templateId=${templateId}`,
    payload,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
// export const getAllTestCaseTemplate = (action) => httpConnection.get(`${FIREFLINK_BASE_URL}/optimize/v1/scriptTemplate/${action ? '?action=' + action : ''}`,{ headers: { projectId :"PJT1001"}});
export const editTestcase = (
  payload,
  parentId,
  scriptID,
  scriptname,
  projectID,
  licenseId
) =>
  httpConnection.put(
    `${BASE_URL}/v1/testcase/updateScript/${parentId}/${scriptID}?oldScriptName=${scriptname}`,
    payload,
    { headers: { projectId: projectID, licenseId: licenseId } }
  );
export const deleteTestCaseReq = (parentID, scriptID, projectId, licenseId) =>
  httpConnection.delete(
    `${BASE_URL}/v1/testcase/${parentID}/script/${scriptID}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

export const GetLocation = (longLoc, latLoc) =>
  httpConnection({
    method: "GET",
    url: `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latLoc}&longitude=${longLoc}&localityLanguage=en`,
  });

export const getDefectsDataCount = (
  projectId,
  licenseId,
  defectFilter,
  state
) =>
  httpConnection.post(
    `${REPORTS_URL}/v1/reports/defect-details/count`,
    defectFilter,
    { headers: { projectId: projectId, licenseId: licenseId, State: state } }
  );
export const getDefectsByEmailIdCount = (projectId, licenseId, emailId) =>
  httpConnection.get(`${BASE_URL}/v1/defects/defectCountByEmail`, {
    headers: { projectId: projectId, licenseId: licenseId, userEmail: emailId },
  });
export const getAllDefectsCount = (projectId, licenseId, emailId) =>
  httpConnection.get(`${BASE_URL}/v1/defects/allDefectCount`, {
    headers: { projectId: projectId, licenseId: licenseId, userEmail: emailId },
  });
export const getDefectsByUserCount = (projectId, licenseId) =>
  httpConnection.get(`${BASE_URL}/v1/defects/defectCountByUser`, {
    headers: { projectId: projectId, licenseId: licenseId },
  });
export const getDefectsByStateCount = (projectId, licenseId, status) =>
  httpConnection.get(`${BASE_URL}/v1/defects/defectCountByState`, {
    headers: { projectId: projectId, licenseId: licenseId, state: status },
  });

// Projectname list API

export const getprojectList = (mailID) =>
  httpConnection.get(`${BASE_URL}/v1/users/projectNames/${mailID}`);

//update defauly templateId in project
export const updatedefaultDefectTemplate = (payload) =>
  httpConnection.put(
    `${BASE_URL}/v1/projects/editDefaultDefectTemplateId`,
    payload
  );

// Signout API
// http://10.10.10.30:8201/optimize/v1/public/user/signout

export const SignOutReq = (payload) =>
  httpConnection.post(`${SIGNOUT_URL}/optimize/v1/user/signout`, payload);

//Testcase
export const getModuleAndChildModuleReq = (
  sort,
  resourceRequired,
  projectId,
  licenseId,
  emailid
) =>
  httpConnection.get(
    `${BASE_URL}/v1/modules/modules/?sort=${sort}&email=${emailid}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
// export const getModuleAndChildModuleReq=(sort,resourceRequired,projectId,licenseId)=>httpConnection.get(`${FIREFLINK_TREE_URL}/optimize/v1/trees/modules/?sort=${sort}&resourceRequired=${resourceRequired}`,{headers:{projectId:projectId,licenseId:licenseId}})
export const gethierarchyModuleReq = (
  sort,
  resourceRequired,
  projectId,
  licenseId
) =>
  httpConnection.get(
    `${FIREFLINK_TREE_URL}/optimize/v1/trees/modulesTrees/?sort=${sort}&resourceRequired=${resourceRequired}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
// export const getExpandedModuleReq=(moduleId,sort,projectId,licenseId)=>httpConnection.get(`${FIREFLINK_TREE_URL}/optimize/v1/trees/moduleExpand/${moduleId}/?sort=${sort}`,{headers:{projectId:projectId,licenseId:licenseId}})
export const getModuleByIdReq = (
  moduleId,
  sort,
  resourceRequired,
  projectId,
  licenseId
) =>
  httpConnection.get(
    `${FIREFLINK_TREE_URL}/optimize/v1/trees/modules/${moduleId}/?sort=${sort}&resourceRequired=${resourceRequired}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const getExpandedModuleReq = (
  moduleId,
  sort,
  projectId,
  licenseId,
  email
) =>
  httpConnection.get(
    `${BASE_URL}/v1/modules/moduleExpand/${moduleId}/?sort=true&email=${email}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

export const createModuleReq = (payload, projectId, licenseId) =>
  httpConnection.post(`${FIREFLINK_BASE_URL}/optimize/v1/modules/`, payload, {
    headers: { projectId: projectId, licenseId: licenseId },
  });
export const EditModuleReq = (
  payload,
  projectId,
  licenseId,
  oldModuleName,
  id
) =>
  httpConnection.put(
    `${BASE_URL}/v1/modules/${id}?oldModuleName=${oldModuleName}`,
    payload,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

export const getModuleDetailsByIdReq = (moduleId, projectId, licenseId) =>
  httpConnection.get(`${FIREFLINK_BASE_URL}/optimize/v1/modules/${moduleId}`, {
    headers: { projectId: projectId, licenseId: licenseId },
  });

export const deleteModuleReq = (moduleId, projectId, licenseId) =>
  httpConnection.delete(
    `${FIREFLINK_BASE_URL}/optimize/v1/modules/${moduleId}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

//  export const getAllModuleTreeReq=(projectId,licenseId)=>httpConnection.get(`${FIREFLINK_TREE_URL}/optimize/v1/trees/modules/?sort=true`,{headers:{projectId:projectId,licenseId:licenseId}})
export const getAllModuleTreeReq = (projectId, licenseId, email) =>
  httpConnection.get(
    `${BASE_URL}/v1/modules/modules/?sort=true&email=${email}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

// export const downloadTestcaseTemplateExcel=(projectId,licenseId)=>httpConnection.get(`${BASE_URL}/v1/testcase/exportTestcaseTemplate/`,{headers:{projectId:projectId,licenseId:licenseId}})

export const downloadTestcaseTemplateExcel = (projectId, licenseId) =>
  httpConnection({
    method: "GET",
    url: `${BASE_URL}/v1/testcase/exportTestcaseTemplate/`,
    responseType: "blob",
    headers: { projectId: projectId, licenseId: licenseId },
  });
export const ImporttestCaseReq = (projectId, licenseId, payload) =>
  httpConnection.post(
    `${BASE_URL}/v1/testcase/importTestcases/`,
    payload,
    { headers: { projectId: projectId, licenseId: licenseId } },
    { type: "formdata" }
  );

export const getExecutionListByEmail = (
  projectId,
  licenseId,
  emailId,
  scriptId,
  page,
  size
) =>
  httpConnection.get(
    `${FIREFLINK_TESTCASE_URL}/optimize/v1/FireCrowdTCExecutionDashBoard/resultsByEmail/${scriptId}/${emailId}?page=${page}&size=${size}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const getExecutionListByStatus = (
  projectId,
  licenseId,
  scriptId,
  status,
  page,
  size
) =>
  httpConnection.get(
    `${FIREFLINK_TESTCASE_URL}/optimize/v1/FireCrowdTCExecutionDashBoard/resultsByStatus/${scriptId}/${status}?page=${page}&size=${size}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const getTestcaseExecutionStatus = (
  projectId,
  licenseId,
  emailId,
  scriptId
) =>
  httpConnection.get(
    `${BASE_URL}/v1/testcase/testCaseStatus/${scriptId}/${emailId}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const getTestcaseExecutionStatusForOwner = (
  projectId,
  licenseId,
  scriptId
) =>
  httpConnection.get(
    `${BASE_URL}/v1/testcase/testCaseStatusForOwner/${scriptId}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

export const UpdateExecutionReq = (
  payload,
  projectId,
  licenseId,
  executionId
) =>
  httpConnection.put(
    `${FIREFLINK_TESTCASE_URL}/optimize/v1/FireCrowdTCExecutionDashBoard/${executionId}`,
    payload,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const createTestcaseExecution = (projectId, licenseId, payload) =>
  httpConnection.post(
    `${FIREFLINK_TESTCASE_URL}/optimize/v1/FireCrowdTCExecutionDashBoard/`,
    payload,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

export const assignTestcaseReq = (payload, projectId, licenseId) =>
  httpConnection.patch(`${BASE_URL}/v1/modules/`, payload, {
    headers: { projectId: projectId, licenseId: licenseId },
  });

//testcase by email
// export const getModuleAndChildModuleReq=(sort,resourceRequired,projectId,licenseId,emailid)=>httpConnection.get(`${BASE_URL}/v1/modules/modules/?sort={sort}&email=${email}`,{headers:{projectId:projectId,licenseId:licenseId}})
export const getModuleAndChildModuleByEmailReq = (
  sort,
  resourceRequired,
  projectId,
  licenseId,
  email
) =>
  httpConnection.get(
    `${BASE_URL}/v1/modules/modules/?sort=${sort}&email=${email}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const gethierarchyModuleByEmailReq = (
  sort,
  resourceRequired,
  projectId,
  licenseId,
  email
) =>
  httpConnection.get(
    `${FIREFLINK_TREE_URL}/optimize/v1/trees/modulesTrees/?sort=${sort}&resourceRequired=${resourceRequired}&email=${email}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
// export const getExpandedModuleByEmailReq=(moduleId,sort,projectId,licenseId,email)=>httpConnection.get(`${FIREFLINK_TREE_URL}/optimize/v1/trees/moduleExpand/${moduleId}/?sort=${sort}&email=${email}`,{headers:{projectId:projectId,licenseId:licenseId}})
// export const getAllModuleTreeByEmailReq=(projectId,licenseId,email)=>httpConnection.get(`${FIREFLINK_TREE_URL}/optimize/v1/trees/modules/?sort=true&email=${email}`,{headers:{projectId:projectId,licenseId:licenseId}})
export const getAllModuleTreeByEmailReq = (projectId, licenseId, email) =>
  httpConnection.get(
    `${BASE_URL}/v1/modules/modules/?sort=true&email=${email}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

export const getExpandedModuleByEmailReq = (
  moduleId,
  sort,
  projectId,
  licenseId,
  email
) =>
  httpConnection.get(
    `${BASE_URL}/v1/modules/moduleExpand/${moduleId}/?sort=true&email=${email}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

export const getModuleIdByEmailReq = (
  moduleId,
  sort,
  resourceRequired,
  projectId,
  licenseId,
  email
) =>
  httpConnection.get(
    `${FIREFLINK_TREE_URL}/optimize/v1/trees/modules/${moduleId}/?sort=${sort}&resourceRequired=${resourceRequired}&email=${email}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

export const checkForUserExistReq = (email, projectId) =>
  httpConnection.get(
    `${BASE_URL}/v1/users/userExists?email=${email}&projectId=${projectId}`
  );

// feedback API's
export const getAllFeedbackTemplateReq = (projectID) =>
  httpConnection.get(`${BASE_URL}/v1/feedback/template/${projectID}`);
export const updateFeedbackTemplateReq = (payload) =>
  httpConnection.put(`${BASE_URL}/v1/feedback/template/`, payload);
export const getFeedbackTemplateByprojectID = (projectID) =>
  httpConnection.get(`${BASE_URL}/v1/feedback/template/${projectID}`);
export const addUserFeedbackReq = (payload) =>
  httpConnection.post(`${BASE_URL}/v1/feedback/user/`, payload);
export const getFeedbackReportsReq = (projectID) =>
  httpConnection.get(`${BASE_URL}/v1/feedbackReports/${projectID}`);
export const getAnswerForParticularQuestionReq = (
  projectID,
  question,
  page,
  size
) =>
  httpConnection.get(
    `${BASE_URL}/v1/feedback/user/value/?projectId=${projectID}&question=${question}&page=${page}&size=${size}`
  );
export const getUserFeedbackByProject = (projectId, email) =>
  httpConnection.get(
    `${BASE_URL}/v1/feedback/user/validate?projectId=${projectId}&email=${email}`
  );

//To check user has certificate
export const hasCertificateReq = (email) =>
  httpConnection.get(`${BASE_URL}/v1/users/validate/${email}`);

//UserCount
export const getUserCountReq = (projectId, leadEmail) =>
  httpConnection.get(
    `${BASE_URL}/v1/users/userCount/${projectId}/${leadEmail}`
  );

//AnswerCount
export const getAnswerCountReq = (projectID, question) =>
  httpConnection.get(
    `${BASE_URL}/v1/feedback/user/count?projectId=${projectID}&question=${question}`
  );

//check Project Exist
export const CheckProjectExistReq = (email, Projectname) =>
  httpConnection.get(
    `${BASE_URL}/v1/projects/projectExists?email=${email}&projectName=${Projectname}`
  );

//testcase Reports API
export const getAllTestcaseReq = (payload, page, size, projectId, licenseId) =>
  httpConnection.post(
    `${BASE_URL}/v1/testcase/testCaseFilter/?page=${page}&size=${size}`,
    payload,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const getAllTesterReq = (projectID) =>
  httpConnection.get(`${BASE_URL}/v1/users/testers/${projectID}`);
export const getAllModuleReq = (projectId, licenseId) =>
  httpConnection.get(
    `${FIREFLINK_TESTCASE_URL}/optimize/v1/FireCrowdTCExecutionDashBoard/getModulesForProject`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

export const getTestCaseResultReportReq = (projectId, licenseId) =>
  httpConnection.get(`${BASE_URL}/v1/testcase/testcaseCount`, {
    headers: { projectId: projectId, licenseId: licenseId },
  });
export const getModuleReq = (projectId, licenseId) =>
  httpConnection.get(`${BASE_URL}/v1/testcase/testcaseCountForModules`, {
    headers: { projectId: projectId, licenseId: licenseId },
  });
export const getAllExecutionResultReq = (
  projectId,
  licenseId,
  payload,
  page,
  size
) =>
  httpConnection.post(
    `${BASE_URL}/v1/testcaseResult?page=${page}&size=${size}`,
    payload,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

export const getTotalTestcaseCount = (payload, projectId, licenseId) =>
  httpConnection.post(`${BASE_URL}/v1/testcase/testCaseFilter/count`, payload, {
    headers: { projectId: projectId, licenseId: licenseId },
  });
export const getTolatExecutionResultCount = (payload, projectId, licenseId) =>
  httpConnection.post(`${BASE_URL}/v1/testcaseResult/count`, payload, {
    headers: { projectId: projectId, licenseId: licenseId },
  });

//Execution dashbord Count
export const getExecutionListCountByEmail = (
  scriptId,
  email,
  projectId,
  licenseId
) =>
  httpConnection.get(
    `${FIREFLINK_TESTCASE_URL}/optimize/v1/FireCrowdTCExecutionDashBoard/resultsCountByEmail/${scriptId}/${email}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

export const getExecutionListCountByStatus = (
  scriptId,
  status,
  projectId,
  licenseId
) =>
  httpConnection.get(
    `${FIREFLINK_TESTCASE_URL}/optimize/v1/FireCrowdTCExecutionDashBoard/resultsCountByStatus/${scriptId}/${status}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

//Reports New API's
export const getAllReportsData = (projectId, licenseID, state) =>
  httpConnection.get(`${REPORTS_URL}/v1/reports`, {
    headers: { projectId: projectId, licenseId: licenseID, state: state },
  });
export const getAlltrainerData = (
  licenseId,
  flinkProjectId,
  projectId,
  state,
  page,
  size
) =>
  httpConnection.get(
    `${REPORTS_URL}/v1/reports/testers/stats?page=${page}&size=${size} `,
    {
      headers: {
        licenseId: licenseId,
        flinkProjectId: flinkProjectId,
        projectId: projectId,
        state: state,
      },
    }
  );
export const getDailyData = (licenseId, flinkProjectId, projectId, state) =>
  httpConnection.get(`${REPORTS_URL}/v1/reports/daily/stats`, {
    headers: {
      licenseId: licenseId,
      flinkProjectId: flinkProjectId,
      projectId: projectId,
      state: state,
    },
  });

export const getAllOSData = (licenseId, flinkProjectId, projectId, state) =>
  httpConnection.get(`${REPORTS_URL}/v1/reports/os/stats`, {
    headers: {
      licenseId: licenseId,
      flinkProjectId: flinkProjectId,
      projectId: projectId,
      state: state,
    },
  });
export const getAllBrowserData = (
  licenseId,
  flinkProjectId,
  projectId,
  state
) =>
  httpConnection.get(`${REPORTS_URL}/v1/reports/browser/stats`, {
    headers: {
      licenseId: licenseId,
      flinkProjectId: flinkProjectId,
      projectId: projectId,
      state: state,
    },
  });
export const getAllDeviceData = (licenseId, flinkProjectId, projectId, state) =>
  httpConnection.get(`${REPORTS_URL}/v1/reports/device/stats`, {
    headers: {
      licenseId: licenseId,
      flinkProjectId: flinkProjectId,
      projectId: projectId,
      state: state,
    },
  });

export const getDefectSegmentData = (
  licenseId,
  flinkProjectId,
  projectId,
  state
) =>
  httpConnection.get(`${REPORTS_URL}/v1/reports/pie-chart/stats`, {
    headers: {
      licenseId: licenseId,
      flinkProjectId: flinkProjectId,
      projectId: projectId,
      state: state,
    },
  });
export const getLocationSegmentData = (
  licenseId,
  flinkProjectId,
  projectId,
  state
) =>
  httpConnection.get(`${REPORTS_URL}/v1/reports/pie-chart/location/stats`, {
    headers: {
      licenseId: licenseId,
      flinkProjectId: flinkProjectId,
      projectId: projectId,
      state: state,
    },
  });

export const gettestcaseAssigneeListBymodule = (
  licenseId,
  projectId,
  moduleID
) =>
  httpConnection.get(
    `${FIREFLINK_BASE_URL}/optimize/v1/FireCrowdTCExecutionDashBoard/Assignee?moduleId=${moduleID}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
export const gettestcaseAssigneeListByTestcase = (
  licenseId,
  projectId,
  moduleID,
  ScriptID
) =>
  httpConnection.get(
    `${FIREFLINK_BASE_URL}/optimize/v1/FireCrowdTCExecutionDashBoard/Assignee?moduleId=${moduleID}&scriptId=${ScriptID}`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );

export const validateUserToken = (payload) =>
  httpConnection.post(
    `${SIGNOUT_URL}/optimize/v1/public/user/validate/user-token`,
    payload
  );

export const getAllModuleByState = (licenseId, flinkProjectId, projectId) =>
  httpConnection.get(`${REPORTS_URL}/v1/reports/getModules`, {
    headers: {
      licenseId: licenseId,
      flinkProjectId: flinkProjectId,
      projectId: projectId,
    },
  });
export const updateTestcaseState = (
  status,
  executionStatus,
  email,
  leademail,
  testCaseId,
  licenseId,
  flinkProjectId,
  payload
) =>
  httpConnection.put(
    `${FIREFLINK_TESTCASE_URL}/optimize/v1/FireCrowdTCExecutionDashBoard/updateMulti?status=${status}&email=${email}&leadEmail=${leademail}&executionStatus=${executionStatus}&testCaseId=${testCaseId}`,
    payload,
    { headers: { LicenseId: licenseId, ProjectId: flinkProjectId } }
  );

export const getPlatformDetails = (deviceType) =>
  httpConnection.get(`${BASE_URL}/v1/otherProject/sort/${deviceType}`);
export const AddTvOrSmartWatchPlatform = (userID, projectID, payload) =>
  httpConnection.post(
    `${BASE_URL}/v1/config/platform/${projectID}/${userID}`,
    payload
  );

// Dashboard API"S

export const getDashboardDetails = () =>
  httpConnection.get(`${REPORTS_URL}/v1/allReports/get`);
export const getDashboradbarchartData = () =>
  httpConnection.get(`${REPORTS_URL}/v1/allReports/graph`);
export const getDashboadPieChartData = (type) =>
  httpConnection.get(`${REPORTS_URL}/v1/allReports/pieChart/${type}`);

//testcase back end search
export const getTestcaseExecitionbySearch = (payload, page, size, key) =>
  httpConnection.post(
    `${BASE_URL}/v1/testcaseResult/search/?key=${key}&page=${page}&size=${size}`,
    payload
  );

//jira intergration API's

export const addJiraAccount = (payload) =>
  httpConnection.post(`${REPORTS_URL}/v1/jira/instance`, payload);
export const updateJiraBasedOnID = (payload) =>
  httpConnection.put(`${REPORTS_URL}/v1/jira/instance/update`, payload);
export const getAllJiraList = (projectID) =>
  httpConnection.get(`${REPORTS_URL}/v1/jira/instance/all/${projectID}`);
export const DeleteJiraInstance = (id) =>
  httpConnection.delete(`${REPORTS_URL}/v1/jira/instance/delete/${id}`);

export const getAllProjectsForDomain = (instanceId) =>
  httpConnection.get(
    `${REPORTS_URL}/v1/jira/metadata?instanceId=${instanceId}`
  );
export const getAllAssignableUsers = (instanceId, jiraProjectId) =>
  httpConnection.get(
    `${REPORTS_URL}/v1/jira/users?instanceId=${instanceId}&jiraProjectId=${jiraProjectId}`
  );
export const getbugDescription = (payload) =>
  httpConnection.post(`${REPORTS_URL}/v1/jira/`, payload);
export const postJiraIssue = (payload) =>
  httpConnection.post(`${REPORTS_URL}/v1/jira/create`, payload, {
    type: "formdata",
  });

//Defect Details API

export const detDefectDetailsByID = (bugId, projectId, licenseId) =>
  httpConnection.get(
    `${APP_MANAGEMENT_BASE_URL}/project/optimize/v1/defects/${bugId}/`,
    { headers: { projectId: projectId, licenseId: licenseId } }
  );
